<script setup lang="ts">
  import { ref } from 'vue'

  const props = defineProps<{
    placeholder?: string
    value?: string
    change?: () => void
    results?: string
  }>()

  const emit = defineEmits<{
    change: [value?: string]
  }>()

  let timeout: ReturnType<typeof setTimeout> | null
  const input = ref<HTMLInputElement | null>(null)

  defineExpose({
    input,
  })

  const onInput = () => {
    timeout && clearTimeout(timeout)

    timeout = setTimeout(() => emit('change', input?.value?.value), 500)
  }
</script>

<template>
  <div class="relative w-full h-10 rounded-lg border border-black-90 justify-start items-center bg-white text-blue-10">
    <des-icon icon="fa-light fa-search" class="absolute top-2 left-4 w-6 h-6"></des-icon>
    <input
      class="absolute pl-12 pr-4 py-2 top-0 bottom-0 left-0 right-0 bg-transparent outline-none placeholder-black-40 hover:placeholder-blue-10"
      :placeholder="placeholder ?? 'Search'"
      :value="value"
      ref="input"
      @input="onInput()"
      @keyup.enter="$emit('change', input?.value)"
    />
  </div>
</template>
